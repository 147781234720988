.container {
  background-color: var(--white);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;
  text-align: center;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-bottom: 1px solid var(--primary);
  font-style: italic;

  @media (--small-viewport) {
    grid-template-columns: auto 1fr auto;
    grid-gap: 1.6rem;
    text-align: left;
  }
}

.position {
  font-size: var(--fontSizeTitle);
  font-weight: var(--fontWeightBold);
  line-height: var(--lineHeightTitle);
}

.detailsContainer {
  display: flex;
  flex-direction: column;

  @media (--small-viewport) {
    min-width: 24rem;
  }
}

.name {
  font-size: var(--fontSizeLarge);
  line-height: var(--lineHeightLarge);
  font-weight: var(--fontWeightBold);
  margin-right: 0.8rem;
}

.orderChange {
  font-weight: var(--fontWeightBold);

  &.positive {
    color: var(--success);
  }

  &.negative {
    color: var(--urgent);
  }
}

.details {
  font-size: var(--fontSizeSmall);
  line-height: var(--lineHeightSmall);
}

.winnings {
  font-size: var(--fontSizeTitle);
  font-weight: var(--fontWeightBold);
  line-height: var(--lineHeightTitle);
}
