.container {
  background-color: var(--white);
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.8rem;
  font-style: italic;

  @media (--small-viewport) {
    grid-gap: 1.6rem;
  }
}

.positionContainer {
  position: relative;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
}

.gradient {
  position: absolute;
  left: 100%;
  width: 1.6rem;
  height: 100%;
  display: block;
  background: linear-gradient(to top left, #fff 0%, #fff 50%, var(--primary) 50%, var(--primary) 100%);
}

.order {
  font-size: var(--fontSizeLarge);
  font-weight: var(--fontWeightBold);
}

.orderChange {
  font-weight: var(--fontWeightBold);

  &.positive {
    color: var(--success);
  }

  &.negative {
    color: var(--urgent);
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.4rem 0 0.4rem 0.8rem;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;

  @media (--small-viewport) {
    flex-direction: row;
  }
}

.description {
  font-size: var(--fontSizeSmall);
  line-height: var(--lineHeightSmall);

  @media (--small-viewport) {
    margin-right: 0.4rem;
  }
}

.name {
  font-weight: var(--fontWeightBold);
  margin-right: 0.8rem;
}

.details {
  font-size: var(--fontSizeSmall);
  line-height: var(--lineHeightSmall);
}

.winnings {
  display: flex;
  align-items: center;
  font-size: var(--fontSizeLarge);
  font-weight: var(--fontWeightBold);
  padding: 0 0.8rem 0 0;
}
