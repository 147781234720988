:root {
  --black: #000;
  --white: #fff;

  --primary: #1CD700;

  --background: #fff;

  --success: #5EC08B;
  --urgent: #a11e35;

  --fontFamily: "Roboto", sans-serif;
  --fontSizeSmall: 1.2rem;
  --fontSizeMedium: 1.4rem;
  --fontSizeLarge: 1.6rem;
  --fontSizeTitle: 3.2rem;
  --lineHeightSmall: 1.4rem;
  --lineHeightMedium: 2rem;
  --lineHeightLarge: 2.4rem;
  --lineHeightTitle: 3rem;
  --fontWeightBlack: 800;
  --fontWeightBold: 700;
  --fontWeightSemiBold: 600;
  --fontWeightRegular: 400;
  --fontWeightLight: 300;
  --fontWeightExtraLight: 200;
}
