.container {
  min-height: 100%;
  background-color: var(--black);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 1.6rem 8rem;

  @media (--small-viewport) {
    justify-content: center;
  }
}

.content {
  @media (--medium-viewport) {
    width: 90rem;
    margin: 0 auto;
  }
}

.header {
  display: grid;
  grid-template-areas:
    "brand ntra"
    "title title";
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  grid-gap: 1.6rem;

  @media (--medium-viewport) {
    grid-template-areas: "brand title ntra";
  }
}

.imgContainer {
  & > .img {
    width: 100%;
  }

  &.brand {
    grid-area: brand;
  }

  &.tvg {
    margin: 0 auto;
  }

  &.ntra {
    grid-area: ntra;
    margin: auto;
    text-align: center;

    & > .img {
      width: 6.4rem;
    }
  }
}

.titleContainer {
  grid-area: title;
  text-align: center;
}

.subtitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.8rem 0;
}

.strikethrough {
  flex: 1;
  background-color: var(--primary);
  height: 0.2rem;
  margin: 0 1.6rem;
}

.subtitle {
  color: var(--primary);
}

.timestamp {
  font-size: var(--fontSizeSmall);
  color: var(--white);
  text-transform: none;
  font-style: italic;
}

.leaderboard {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;

  @media (--small-viewport) {
    width: auto;
    grid-template-columns: 1fr 1fr;
  }
}

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;

  & > li:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

.loadingContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
