html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font-family: var(--fontFamily);
  font-size: var(--fontSizeMedium);
  line-height: var(--lineHeightMedium);
  font-weight: var(--fontWeightRegular);
  letter-spacing: 0.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  margin: 0;
  padding: 0;
  background: var(--background);
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input, textarea, select, button {
  font-family: var(--fontFamily);

  &:disabled {
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: 0 0 1px 2px var(--primary);
    outline: none;
  }
}

h1, h2, h3, h4, h5, p {
  margin: 0;
  padding: 0;
}
